<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header"> Reviews</h4>
                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-8 col-sm-12">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchReviewQuestions()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..."   class="form-control" >
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-12">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mt-1">Search</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2">
                      <select class="form-control" id="" @change="getReviews()"  v-model="reviewquestion">
                        <option value="">Select Review Question</option>
                        <option v-for="question in reviewQuestions" :key="question.id" :value="question.id">{{question.name}}</option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <select class="form-control" id="" @change="getReviews()"  v-model="storeId">
                        <option value="">Select Store</option>
                        <option v-for="store in stores" :key="store.id" :value="store.id">{{store.branch_name}}</option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <select class="form-control" id="" @change="getReviews()"  v-model="products">
                        <option value="">Select Product</option>
                        <option v-for="menuitem in menuItems" :key="menuitem.menu_item.id" :value="menuitem.menu_item.id">{{menuitem.menu_item.item_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                
              </div>
              <hr>
            </div>
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="review_table">
                  <div class="table_section">
                    <table class="table  table-hover">
                      <thead>
                        <tr>
                          <th width="5px">Customer</th>
                          <th scope="col">Order Summary</th>
                          <th scope="col">Item Reviews </th>
                          <th scope="col">Order Review</th>
                          <th scope="col">Date Reviewed</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="!loading">
                        <tr v-for="review in reviews" :key="review.id">
                          <td>
                            <span>{{review.customer_name}}</span> <br>
                            <div class="mt-2"> <small >{{review.total_orders > 1 ? review.total_orders + " orders" : "New customer"}}</small> </div>
                          </td>
                          <td>
                            <span>{{review.store}}</span><br>
                            <span>{{review.currency}} {{formatPrice(review.total_price)}}</span>
                          </td>
                          <td>
                            <div class="review_quiz mt-2" v-if="review.review_items[0] !=undefined">
                              <span v-for="(rquiz,index) in review.review_items[0].review_questions" :key="index">
                                {{rquiz.name}}<span v-if="index+1 < review.review_items[0].review_questions.length "> . </span>
                              </span>
                            </div>
                            
                            <div class="reviews mt-3">
                              <span v-if="review.positive_reviews > 0"><i class='fa fa-thumbs-up'></i> {{review.positive_reviews > 1 ? review.positive_reviews + ' Items' : review.positive_reviews  +' Item'}} </span> &nbsp;&nbsp;
                              <span v-if="review.negative_reviews > 0"><i class='fa fa-thumbs-down'></i> {{review.negative_reviews > 1 ? review.negative_reviews +' Items' : review.negative_reviews+ ' Item'}} </span>
                            </div>
              
                          </td>
                          <td>
                            <!-- stars rating -->
                            <div class="rating">
                              <ul class="list">
                                <li :key="star" v-for="star in maxStars" :class="[{ 'review_star_rate': star <= review.stars },{ 'five_star_rate': review.stars == maxStars }]"  class="star">
                                  <span class="far fa-star"></span>
                                </li>
                              </ul>
                            </div>
                            <!-- <span v-if="review.status"><i class='fa'>&#xf087;</i></span> <span v-else><i class='fa'>&#xf088;</i></span><br> -->
                            <div class="mt-4">
                              <div >
                                <span v-for="(or,index) in review.order_reviews" :key="index">
                                  {{or.name}}<span v-if="index+1 < review.order_reviews.length "> . </span>
                                </span>
                              </div>

                            </div>
                          </td>
                          <td>{{formatDate(review.created_at,1)}}</td>
                          <td>
                            <button class="btn review_btn btn_reserveport_orange mt-1" data-toggle="modal" :data-target="'#review_detail'+review.id">view</button>
                            <br>
                            <button v-if="!review.replied" class="btn review_btn btn-blue mt-1">Reply</button>
                            <div v-if="review.replied" class="mt-1"><i class="fa fa-check"></i> Replied</div>
                          </td>
                          <!-- Modal -->
                          <div class="modal order_modal right" :id="'review_detail'+review.id"  aria-labelledby="myModalLabel2">
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header order_modal_header">
                                  <h3 class="modal-title">Review</h3><span class="Cname">{{review.customer_name}}</span>
                                  <button type="button" class="close button_close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                </div>

                                <div class="modal-body">
                                  <div class="padd">
                                    <div class="customer_details">
                                      <span> <b>{{review.customer_name}}</b></span> <br>
                                      <div class="mt-2"> <small >{{review.total_orders > 1 ? review.total_orders + " orders" : "New customer"}}</small> </div>
                                    </div>
                                    <hr>
                                    <div class="order_review">
                                      <b><span v-if="review.status"><i class='fa fa-thumbs-up'></i></span> <span v-else><i class='fa fa-thumbs-down'></i></span> {{review.store}} </b>
                                      <div class="mt-2">
                                        <div v-if="review.comments" class="mb-1">
                                          {{review.comments}}
                                        </div>
                                        <div class="mt-2" >
                                          <span class="badge badge_review mr-1" v-for="(or,index) in review.order_reviews" :key="index">
                                            {{or.name}}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <hr>
                                    <div class="items_review">
                                      <div class="mt-3" v-for="item in review.review_items" :key="item.id">
                                        <span><b><span v-if="item.status"><i class='fa fa-thumbs-up'></i></span> <span v-else><i class='fa fa-thumbs-down'></i></span> {{item.item_name}}</b></span>
                                        <div v-if="item.item_comments" class="mb-1">
                                          {{item.item_comments}}
                                        </div>
                                        <div class="mt-2">
                                          <span class="badge badge_review mr-1" v-for="reviewquiz in item.review_questions" :key="reviewquiz.name">
                                            {{reviewquiz.name}}
                                          </span>
                                        </div>
                                        
                                      </div>
                                    </div>

                                    <!--  -->
                                    <!--  -->
                                    

                                  </div>
                                </div>

                              </div><!-- modal-content -->
                            </div><!-- modal-dialog -->
                          </div><!-- modal -->
                        </tr>
                      </tbody>
                      <tfoot v-if="!loading">
                        <tr>
                          <td colspan="8">
                            <div id="pagination" v-if="totalPages > 1">
                              <ul class="showItems">
                                <li>Show Items:
                                  <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                    <option :value="10">10</option>
                                    <option :value="20">20</option>
                                    <option :value="30">30</option>
                                    <option :value="40">40</option>
                                    <option :value="50">50</option>
                                  </select>
                                </li>

                              </ul>
                              <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />

                            </div>

                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div v-if="loading" class="loading-cover">
                    <div class="loader"> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Pagination from '@/pages/pagination/pagination.vue';
// import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
// const toast = useToast();

export default {
    components: {
        Pagination,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            stores: [],
            // configuresStores:[],
            loading: false,
            spinner: false,
            page: 1,
            reviews: [],
            reviewItems: [],
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: 10,
            reviewQuestions: [],
            reviewquestion:'',
            enterpageno: '',
            search: '',
            debounce: null,
            storeId: '',
            menuItems: [],
            products: '',
            maxStars: 5,
            stars: 3
        }
    },
    validations() {
        return {
            name: { required },
            status: { required },
            type: { required },
            questiontype: { required }
        }
    },
    created: async function () {
        this.getReviews();
        this.getReviewQuestions();
        this.getBranches();
        this.getItems();
    },
    methods: {
      searchReviewQuestions() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getReviews();
            }, 1500)
        },
        async getReviews() {
            this.loading = true;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = `${this.dashBaseUrl}/reviews/${this.merchantID}`;
            url = new URL(url);
            url.searchParams.set('page', this.page);
            url.searchParams.set('size', this.recordsPerPage);
            if(this.reviewquestion !=''){
              url.searchParams.set('review_question',this.reviewquestion)
            }
            if (this.storeId != '') {
                url.searchParams.set('store_id', this.storeId);
            }
            if(this.products !=''){
              url.searchParams.set('item_id',this.products);
            }
            if(this.search !=''){
              url.searchParams.set('q',this.search)
            }
            try{
              const response = await this.axios.get(url, config)
              if (response.status === 200) {
                  this.reviews = response.data.data.data;
                  this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
                  this.totalRecords = response.data.data.total
                  this.loading = false
              }
            }catch(error){
              this.loading = false;
            }
        },
         async getReviewQuestions() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/review-questions/${this.merchantID}`;
            try{
              const response = await this.axios.get(url, config)
              if (response.status === 200) {
                  this.reviewQuestions = response.data.data;
              }
            }catch(error){
              // this.loading = false;
            }
        },
        async getItemReviews(){
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = `${this.dashBaseUrl}/item-reviews/${this.merchantID}`;
            url = new URL(url);
            url.searchParams.set('page', this.page);
            url.searchParams.set('size', this.recordsPerPage);
            try{
              const response = await this.axios.get(url, config)
              if (response.status === 200) {
                  this.reviewItems = response.data.data.data;
                  // this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
                  // this.totalRecords = response.data.data.total
                  // this.loading = false
              }
            }catch(error){
              this.loading = false;
            }
          // item-reviews
        },
        async getBranches() {
          const url = this.baseUrl + "/branch?merchant_id="+ this.merchantID;
            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                if (response.status === 200) {
                    if (response.data.data !== null) {
                        const stores = response.data.data;
                        this.stores = stores;
                    }
                }
            }).catch((err) => {
                console.log('error', err);
            })

        },
        async getItems() {
            let url = `${this.baseUrl}//menu_item`;
            url  = new URL(url);
            url.searchParams.set('merchant_id',this.merchantID);
            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                this.menuItems = response.data.data
            }).catch((err) => {
              const message = typeof err.response !== "undefined" ? err.response.data.message : err.message;
              console.warn("error", message);
            })
        },
        onPageChange(page) {
            this.page = page
            this.getReviewQuestions();
        },
        onChangeRecordsPerPage() {
            this.getReviewQuestions();
        },

    },
    mixins: [ReusableDataMixins, ReusableFunctionMixins]
}
</script>

